window.addEventListener('DOMContentLoaded', function () {
    const menu = document.getElementById('mobile-menu');
    const menuLinks = document.querySelectorAll('#mobile-menu a');
    if (menuLinks && menu) {
        for (let i = 0; i < menuLinks.length; i++) {
            menuLinks[i].addEventListener('click', function (e) {
                e.preventDefault();
                menu.classList.remove('!left-0');
            });
        }
    }
});