import '../../../../../modules/system/assets/js/framework';
import '../../../../../modules/system/assets/js/framework.extras';

import '../../../partials/burger/burger'
import '../../../partials/mobile-menu/mobile-menu'
import '../../../partials/google-map/google-map'

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
const lightbox = new PhotoSwipeLightbox({
    gallery: '#gallery',
    children: 'a',
    pswpModule: PhotoSwipe,
});
lightbox.init();


// import Swiper from 'swiper';
// // import Swiper styles
// import 'swiper/css';
//
// const swiper = new Swiper('.swiper', {
//     // Optional parameters
//     direction: 'vertical',
//     loop: true,
//
//     // If we need pagination
//     pagination: {
//         el: '.swiper-pagination',
//     },
//
//     // Navigation arrows
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//     },
//
//     // And if we need scrollbar
//     scrollbar: {
//         el: '.swiper-scrollbar',
//     },
// });

// import PhotoSwipeLightbox from "../../../../node_modules/photoswipe/dist/photoswipe.esm.min.js";
// import "photoswipe/style.css";
//
// const lightbox = new PhotoSwipeLightbox({
//     gallery: "#my-gallery",
//     children: "a",
//     pswpModule: () => import("photoswipe")
// });
//
// lightbox.init();

window.getHeaderHeight = function (){
    const header = document.getElementById('header');
    return header.offsetHeight
}
//
// window.scrollToSecondScreen = function () {
//     const sectionAbout = document.getElementById('section-about');
//     const header = document.getElementById('header');
//     const yOffset = header.offsetHeight;
//     const y = sectionAbout.getBoundingClientRect().top + window.pageYOffset - yOffset;
//     window.scrollTo({top: y, behavior: 'smooth'});
// }

// scroll to anchor
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const element = document.querySelector(this.getAttribute('href'));
        const y = element.getBoundingClientRect().top + window.pageYOffset - getHeaderHeight();
        window.scrollTo({top: y, behavior: 'smooth'});
    });
});

// (function($){
// // highlight current menu item
// // Cache selectors
// var topMenu = $("#main-menu"),
//     topMenuHeight = topMenu.outerHeight(),
//     // All list items
//     menuItems = topMenu.find("a"),
//     // Anchors corresponding to menu items
//     scrollItems = menuItems.map(function(){
//         var item = $($(this).attr("href"));
//         if (item.length) { return item; }
//     });
//
// // Bind to scroll
// $(window).scroll(function(){
//     // Get container scroll position
//     var fromTop = $(this).scrollTop()+topMenuHeight;
//
//     // Get id of current scroll item
//     var cur = scrollItems.map(function(){
//         if ($(this).offset().top < fromTop)
//             return this;
//     });
//     // Get the id of the current element
//     cur = cur[cur.length-1];
//     var id = cur && cur.length ? cur[0].id : "";
//     // Set/remove active class
//     menuItems
//         .removeClass("!text-amber-300")
//         .end().filter("[href='#"+id+"']").addClass("!text-amber-300");
// });
// })(jQuery);