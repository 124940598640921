window.addEventListener('DOMContentLoaded', function () {
    const burger = document.querySelector('.js-mobile-menu-trigger');
    const menu = document.getElementById('mobile-menu');
    if (burger && menu) {
        burger.addEventListener('click', function (e) {
            e.preventDefault();
            menu.classList.toggle('!left-0');
            burger.classList.toggle('group');
        });
    }
});